import classNames from 'classnames'

import Leaderboard from '@/components/Layout/Leaderboard/Leaderboard'
import PleaseSupportUs from '@/components/PleaseSupportUs/PleaseSupportUs'
import { useTheme } from '@/components/ThemeProvider/ThemeProvider'
import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

import styles from './Main.module.scss'

const LEADERBOARD_BLACKLIST = ['newsletter']

const SIDEBAR_BLACKLIST = [
  'about-us',
  'account',
  'apply',
  'bug-report',
  'confirm-password',
  'contact-us',
  'forgot-password',
  'newsletter',
  'privacy',
  'reset-password',
  'signin',
  'signup',
]

type Props = {
  artCrop?: string
  children: React.ReactNode
}

const GRADIENT_DARK = 'linear-gradient(to bottom, rgba(18, 19, 21, 0.5), rgba(18, 19, 21, 1))'
const GRADIENT_LIGHT = 'linear-gradient(to bottom, rgba(249, 250, 251, 0.5), rgba(249, 250, 251, 1))'

const Main = ({ artCrop, children }: Props) => {
  const { dir, query } = useDirectories()
  const patreonTier = usePatreonTier()
  const [theme] = useTheme()

  const gradient = theme === 'dark' ? GRADIENT_DARK : GRADIENT_LIGHT
  const loadAds = !patreonTier || !!query?.ads
  const showLeaderboard = loadAds && !LEADERBOARD_BLACKLIST.includes(dir)
  const showSidebar = loadAds && !SIDEBAR_BLACKLIST.includes(dir)

  return (
    <>
      {artCrop && (
        <div className={styles.backgroundContainer}>
          <div
            className={styles.background}
            style={{
              backgroundImage: `${gradient}, url(${artCrop})`,
            }}
          />
        </div>
      )}
      <main className={styles.main}>
        {showLeaderboard && <Leaderboard />}
        <div className={classNames('d-flex flex-grow-1 p-3', { 'pe-lg-0': showSidebar })}>
          <div className='d-flex w-100'>
            <div className={showSidebar ? styles.left : 'w-100'}>{children}</div>
            {showSidebar && <div className={classNames(styles.sidebar, 'mvSidebar1')} />}
          </div>
        </div>
      </main>
    </>
  )
}

export default Main
